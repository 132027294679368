import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {GlobalContext} from "./Context";


interface BreadcrumbProps {
    text: string,
    white?: boolean,
    additionalClasses?: string,
}

const Breadcrumb = ({text, white, additionalClasses} : BreadcrumbProps) => {
    const classes = [
        'c-breadcrumbs',
    ];

    if (additionalClasses)
        classes.push(additionalClasses);


    if (white)
        classes.push('c-breadcrumbs--white');

    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {setTimeout(() => {setIsLoaded(true)}, 100)}, []);

    return (
        <div className={`${classes.join(' ')} ${isLoaded ? 'e-active--delay' : ''}`}>
            <div className={"e-effect e-fade e-move--down-gentle"}>{text}</div>
        </div>
    )
};

export default Breadcrumb;
