import * as React from "react";
import {Helmet} from "react-helmet";
import Navigation from "../components/Navigation";
import {graphql, Link} from "gatsby";
import Logo from "../components/Logo";
import * as _ from "lodash";
import Breadcrumb from "../components/Breadcrumb";
import {getPageTitle} from "../components/Helpers/Meta";
import Fade from "../transitions/Fade";
import {useEffect, useState} from "react";
import {off, on, trigger} from "../components/Events";
import TransitionLink  from 'gatsby-plugin-transition-link'
import {isIOS} from "../components/Helpers/Device";
import MediaBlock from "../components/MediaBlock";

export const PAGE_NOT_FOUND_QUERY = graphql`
query PageNotFoundQuery($id: String!) {
  content: contentfulPageNotFoundPage(
      id: { eq: $id }
    ) {
        metatitle
        errorMessage
        errorSubtitle
        video {
            file {
                contentType
                url
            }
        }
    }
}
`;
const PageNotFoundTemplate = ({data}) => {
    const {
        content: {
            metatitle,
            errorMessage,
            errorSubtitle,
            video
        }
    } = data;

    const hidePage = () => {
        Fade.animate([
            { target: '.o-layout', type: Fade.TYPE_OPACITY },
            { target: '.o-section', type: Fade.TYPE_OPACITY },
            { target: '.c-logo', type: Fade.TYPE_OPACITY },
            { target: '.c-breadcrumbs', type: Fade.TYPE_OPACITY }
        ])
    };

    useEffect(() => {
        on('page:out', hidePage)

        return () => {
            off('page:out', hidePage)
        }
    }, [])

    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {setTimeout(() => {setIsLoaded(true)}, 100)}, []);

    return (
        <>
            <Logo state={Logo.WHITE} />
            <Navigation />
            <Helmet>
                <title>{getPageTitle({title: '404'})}</title>
                <style>
                    {/*{`body { background-color: #000000; }`}*/}
                </style>
            </Helmet>
            <Breadcrumb text={"Oeps sorry"} white={true} />

            <div className={`o-section c-404-fg o-layout--full-page ${isLoaded ? 'e-active--delay' : ''}`}>
                <div className={"c-404-container"}>
                    <h1 className={"c-404-title e-effect e-fade e-move--down-gentle"}><span>404</span></h1>
                    <div className={"c-404-message e-effect e-fade e-move--down-gentle"}>{errorMessage}</div>
                    <div className={"c-404-subtitle e-effect e-fade e-move--down-gentle"}>{errorSubtitle}</div>
                </div>
            </div>
            <div className={`o-section c-404-bg o-layout o-layout--full-page u-columns-24 ${isLoaded ? 'e-active--delay' : ''}`}>
                <MediaBlock item={video} additionalClasses={"o-object-fit--cover"} />
            </div>
        </>
    )
};

export default PageNotFoundTemplate;
